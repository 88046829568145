<template>
  <div class="container-experiences">
    <div class="section-header">
      <h2 class="section-title">{{ $t(`${base}.top.title`) }}</h2>
      <p class="section-description">{{ $t(`${base}.top.description`) }}</p>
    </div>

    <LazyLoading
      :key="`top`"
      :loading="products.length === 0"
      :loaded="lazy.top"
      @loaded="(e) => (lazy.top = e)"
    >
      <Swiper v-if="lazy.top" ref="swiperExperiences" :options="swiperOption">
        <SwiperSlide v-for="(post, i) in products" :key="i">
          <lad-card
            :item="post"
            :base="base"
            :to="{
              name: 'product-permalink',
              params: {
                type: $t(`router.product_permalink.type.${post.type}`),
                permalink: post.permalink,
              },
            }"
          />
        </SwiperSlide>

        <div slot="button-prev" class="swiper-button-prev"></div>
        <div slot="button-next" class="swiper-button-next"></div>
      </Swiper>

      <template slot="css-skeleton">
        <LadExperienceSwiperLoader />
      </template>
    </LazyLoading>
  </div>
</template>

<script>
import LadExperiences from './common'

export default {
  name: 'LadExperiencesTopList',
  extends: LadExperiences,
  data() {
    return {
      lazy: { top: false },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const { data, status } = await this.$axios.get(
          `/api/v1.5/experiences/list/top`
        )

        if (status === 'success') {
          this.setData(data)
        }
      } catch (error) {}
    },
  },
}
</script>
