<template>
  <div class="lad-experiences">
    <el-container v-if="withContainer">
      <el-main>
        <LadExperiencesTopList />
      </el-main>
    </el-container>

    <LadExperiencesTopList v-else />
  </div>
</template>

<script>
import CacheComponentMixin from '@/utils/mixin/cache-components'
import LadExperiencesTopList from './components/top-list'

export default {
  name: 'LadExperiencesTop',
  components: { LadExperiencesTopList },
  mixins: [CacheComponentMixin],
  props: {
    // eslint-disable-next-line vue/no-boolean-default
    withContainer: { type: Boolean, default: true },
  },
}
</script>
