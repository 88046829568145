var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-experiences"},[_c('div',{staticClass:"section-header"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.top.title`)))]),_vm._v(" "),_c('p',{staticClass:"section-description"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.top.description`)))])]),_vm._v(" "),_c('LazyLoading',{key:`top`,attrs:{"loading":_vm.products.length === 0,"loaded":_vm.lazy.top},on:{"loaded":(e) => (_vm.lazy.top = e)}},[(_vm.lazy.top)?_c('Swiper',{ref:"swiperExperiences",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.products),function(post,i){return _c('SwiperSlide',{key:i},[_c('lad-card',{attrs:{"item":post,"base":_vm.base,"to":{
            name: 'product-permalink',
            params: {
              type: _vm.$t(`router.product_permalink.type.${post.type}`),
              permalink: post.permalink,
            },
          }}})],1)}),_vm._v(" "),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_vm._v(" "),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2):_vm._e(),_vm._v(" "),_c('template',{slot:"css-skeleton"},[_c('LadExperienceSwiperLoader')],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }